<template>
  <section class="page-property">
    <div class="banner">
      <!-- <p class="hd">JWP PROPERTY SERVICE</p>
      <p class="title">
        <span>Property Services</span>
      </p>
      <p class="desc">
        <span
          >With more than ten years of international service experience, JWP
          stands as the leadership position of professional, private, and
          dedicated, providing a full range of property services and
          corresponding lifestyle services afterward, throughout globe service
          teams and large varieties of service partners.</span
        >
      </p> -->
    </div>
    <div class="summary">
      <div
        class="wrap"
        v-lazy:background-image="
          '//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/beijing.png'
        "
      >
        <div class="each">
          <p class="k">Position</p>
          <p class="v">
            A comprehensive platform of cross-border asset management and
            lifestyle services for upper-middle-class families worldwide.
          </p>
        </div>
        <div class="each">
          <p class="k">Mission</p>
          <p class="v">
            Shorten the distance of the world, broaden the boundaries of life.
          </p>
        </div>
        <div class="each">
          <p class="k">Service Principles</p>
          <p class="v">
            Based on demand, focused on products, emphasis on services.
          </p>
        </div>
      </div>
    </div>
    <div class="run">
      <p class="title">
        <span class="van-hairline--bottom">Hosting on Clients’ Behalf</span>
      </p>
      <div class="steps">
        <div class="sub-title">Service</div>
        <div class="item">
          <div>
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/tupian1.png"
            />
          </div>
          <div class="name">
            <img
              class="num"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/shuzi1.png"
            />
            Customer Service
          </div>
          <div class="desc">
            Five-stars standard services committed to providing professional and
            quality
          </div>
        </div>
        <div class="item">
          <div>
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/tupian2.png"
            />
          </div>
          <div class="name">
            <img
              class="num"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/shuzi2.png"
            />
            Overseas Managing Teams
          </div>
          <div class="desc">
            Licensed property management teams globally with acknowledged credit
            and mission
          </div>
        </div>
        <div class="item">
          <div>
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/tupian3.png"
            />
          </div>
          <div class="name">
            <img
              class="num"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/shuzi3.png"
            />
            One-stop Hosting
          </div>
          <div class="desc">
            Create a one-stop service system for overseas investors, away fom
            complicacy
          </div>
        </div>
        <div class="item">
          <div>
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/tupian4.png"
            />
          </div>
          <div class="name">
            <img
              class="num"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/shuzi4.png"
            />
            Third-party Payment
          </div>
          <div class="desc">
            Pay local currency with WeChat & Alipay, automatically converted to
            Euros
          </div>
        </div>

        <div class="sub-title">Technology</div>
        <div class="item">
          <div>
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/tupian5.png"
            />
          </div>
          <div class="name">
            <img
              class="num"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/shuzi1.png"
            />
            IMS App+
          </div>
          <div class="desc">
            Review and manage properties without downloading the app, enjoy the
            profits and benefits from applet within IMS App like WeChatenjoy the
            profits and benefits from applet within IMS App like WeChat
          </div>
        </div>
        <div class="item">
          <div>
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/tupian6.png"
            />
          </div>
          <div class="name">
            <img
              class="num"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/shuzi2.png"
            />
            Work Order System
          </div>
          <div class="desc">
            Internal business management system to avoid omissions and improve
            service quality
          </div>
        </div>
        <div class="item">
          <div>
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/tupian7.png"
            />
          </div>
          <div class="name">
            <img
              class="num"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/shuzi3.png"
            />
            lntelligent Property Management System
          </div>
          <div class="desc">
            Higher hosting revenue gained and less cost-covering from
            intelligent solutions with more scalability
          </div>
        </div>
        <div class="item">
          <div>
            <img
              class="img"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/tupian8.png"
            />
          </div>
          <div class="name">
            <img
              class="num"
              src="//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/shuzi4.png"
            />
            Bonni Superintendent
          </div>
          <div class="desc">
            Support partners in tracking signed customer’s service updates
            online
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  beforeCreate() {
    document.title = "Property Services";
  },
};
</script>

<style lang="less" scoped>
.page-property {
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 360px;
    background: url("//files-jwp-com.oss-ap-southeast-1.aliyuncs.com/images/mobile/property-services/bg.png")
      no-repeat center center / cover;
    .hd {
      padding-bottom: 83px;
      font-size: 22px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 30px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    }
    .title {
      padding-bottom: 16px;
      span {
        padding-bottom: 4px;
        font-size: 20px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        border-bottom: 2px rgba(255, 255, 255, 0.5) solid;
      }
    }
    .desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 22px 39px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      text-align: center;
      span {
        display: inline-block;
        line-height: 17px;
      }
    }
  }
  .summary {
    padding: 24px 0 40px;
    .wrap {
      width: 331px;
      height: 288px;
      margin: 0 auto;
      padding: 20px;
      background-color: #ffffff;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .each {
        &:not(:last-child) {
          margin-bottom: 14px;
        }
        .k {
          font-size: 15px;
          font-weight: bold;
          color: rgba(38, 38, 38, 1);
          line-height: 21px;
        }
        .v {
          margin-top: 4px;
          font-size: 14px;
          font-weight: 300;
          color: rgba(38, 38, 38, 1);
          line-height: 20px;
        }
      }
    }
  }
  .run {
    padding: 0 0 0px;
    .title {
      padding-bottom: 20px;
      text-align: center;
      span {
        padding-bottom: 4px;
        font-size: 16px;
        font-weight: 300;
        color: #003C7E;
        &::after {
          border-color: #003C7E;
        }
      }
    }

    .steps {
      .sub-title {
        font-size: 18px;
        color: #000000;
        line-height: 25px;
        margin-bottom: 10px;
        font-weight: 600;
        padding: 0 20px 0px;
      }
      .item {
        padding: 0 20px 0px;

        .name {
          display: flex;
          align-items:flex-start;
          font-weight: 600;
          font-size: 16px;
          color: #0d0d0d;
          line-height: 22px;
          margin-top: 10px;

          .num {
            width: 20px;
            margin-right: 5px;
          }
        }
        .img {
          width: 100%;
        }
        .desc {
          font-weight: 400;
          font-size: 14px;
          color: #2c2c2c;
          line-height: 18px;
          margin-bottom: 25px;
        }
      }
    }
  }
}
</style>
